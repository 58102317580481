<template>
    <BT-Blade-Items
        navigation="courier-weekly-stats"
        :onPullAsync="pullStats"
        :params="{ test: 'a' }"
        title="Delivery Statistics">
        <template #body="{ allItems }">
            {{ allItems }}
        </template>
    </BT-Blade-Items>
</template>

<script>
const LASTUPDATEKEY = 'last-delivery-stats-update'

export default {
    name: 'Delivery-Statistics',
    data: function() {
        return {

        }
    },
    methods: {
        async pullStats() {
            var lastUpdate = localStorage.getItem(LASTUPDATEKEY)
            var res = await this.$BlitzIt.api.getAll(
                'courier-weekly-stats',
                {
                    lastUpdate: lastUpdate
                },
                null,
                null,
                false);

            console.log(res)

            localStorage.setItem(LASTUPDATEKEY, this.$BlitzIt.auth.createUTC())

            return res.data.data;
        }
    }
}
</script>